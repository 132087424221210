@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@600&family=Noto+Sans:wght@300&display=swap');
.headerNavigation
{
     display: flex;
     position: sticky !important;
     top:0px;
     justify-content: space-between;
}
.navigationToggle, .navigationToggleLeft
{
     position: fixed;
     z-index: 20;
     right: 0px;
     box-shadow: 2px 1px 5px gray;
     top: 0px;
     height: 100vh;
     background: white;
     opacity: 0.8;
     width: 30px;
     display: flex;
     justify-content: center;
     align-items: center;
}
.navigationToggleLeft
{
     position: absolute;
     top: 0;
     left: 0;
}
.navigationLinksUL
{
     display: none;
}
.sideBarContainer .navigationLinksUL
{
     display: block;
}
@keyframes bounce {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
.navigationIconContainer
{
     height: 10vh;
     width: 100%;
     animation: 1s infinite alternate bounce;
}
.navigationIcon
{
     font-weight: bold;
     font-size: 1.7em;
     padding: 0 3px;
}
.sideBarContainer
{
     box-sizing: border-box;
     transform: translateX(300px);
     transition: 600ms linear 0s;
}
.openSideBar
{
     transform : translateX(0);
     animation: 600ms linear 0s;
}
.title
{
     font-size: 2em;
     line-height: 10px;
     margin-bottom: 3px;
     font-weight: bold;
}
.subtitle
{
     font-weight: normal;
}
.login-wrapper form
{
     margin: 10vh 0
}
.button-container
{
     display: flex;
     justify-content: center;
}
button{
     cursor: pointer;
}
.solid-button
{
     background: #252160;
     border: none;
     box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
     color: white;
     padding: 15px 20px;
     margin-top: 20px;
     width: 70%;
}
.solid-button:disabled
{
     background: gray;
     color: black;
     padding: 15px 40px;
     border: none;
}
.center-paragraph
{
     text-align: center;
}
.flex-center
{
     display: flex;
     justify-items: center;
     align-items: center;
     margin-top: 40px
}
.flex-between
{
     display: flex;
     justify-content: space-between;
     align-items: center;
}
.flex-start
{
     display: flex;
     justify-content: start;
     align-items: center;
}
.mr-25
{
     margin-right: 25px;
}
.flex-center span
{
     font-size: 1.5em;
     padding: 0 4px;
}
.errorMessage, .successMessage
{
     color: red;
     text-align: left;
     font-weight: bold;
     margin: 3px 0;
     font-size: 0.8em;
}
.successMessage
{
     color: green;
}
/** Spinner **/
.loading-state {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  /* opacity: 0; */
  transition: opacity 1s ease-in-out;
  justify-content: center;
  align-items: center;
}
.showLoader
{
     opacity: 1;
     display: flex;
     transition: opacity 1s ease-in-out;
     z-index: 0;
}
.loading {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 10px solid #fff;
  border-top-color: #252160;
  animation: loading 1s linear infinite;
}
.sideBarContainer .navigationLinksUL li
{
     padding: 10px 0;
}
@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}
/** End of spinner**/
@import './css/desktop.css';
@import './css/dashboard.css';
@import './css/admin_dashboard.css';
