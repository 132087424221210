.admin-layout-dashboard-container
{
     display: flex;
     justify-content: start;
     align-items: start;
}
.arrow-entity
{
     font-weight: bold;
     font-size: 1.1em;
     margin-left: 5px;
}
.sidebar-for-admin
{
     width: 19vw;
     box-sizing: border-box;
     height: 100vh;
     position: sticky;
     overflow: hidden;
     top: 0;
     background: #2F49D1;
     color: white;
     padding: 25px;
     /* background: #200533; */
}
.admin-layout-dashboard-container > section
{
     background: #F4F5F7;
     padding: 25px  15px 150px 40px;
     overflow: hidden;
     box-sizing: content-box;
     width: 100%;
}
.admin-nav-h1
{
     color: white;
     font-size: 1.3em;
     text-align: center;
     margin: 20px 0;
}
.sidebar-for-admin .ul-major-click
{
     background: rgba(255, 255, 255, 0.2);
     display: flex;
     justify-content: start;
     text-decoration: none;
     gap: 5px;
     text-align: left;
     margin-top: 50px;
     align-items: center;
     color: white;
     width: 100%;
     border: none;
     /* box-shadow: 1px 2px 1px #ff */
     padding: 10px;
}
.sidebar-for-admin ul
{
     display: grid;
     margin-top: 100px;
     gap: 10px;
     padding: 0;
     justify-content: start;
}
.sidebar-for-admin ul .dashboard-icon
{
     height: 27px;
     width: 27px;
}
.sidebar-for-admin ul li
{
     width: 100%;
     display: flex;
     justify-content: start;
     gap: 15px;
     align-items: center;
}
.sidebar-for-admin ul .anchorTag
{
     color: white;
     width: inherit;
     /* padding: 15px 30px 15px 0; */
}
.sidebar-for-admin p
{
     color: white;
     margin-top: -15px;
}
.admin-layout-dashboard-container header h1
{
     font-size: 2.1em;
     margin: 5px 0;
}
.adminpage-section-beside-nav
{
     min-height: 85vh;
}
.admin-home-history-wrapper
{
     width: 35%;
}
.admin-home-history-container
{
     width: 100%;
     background: white;
     padding: 20px 15px ;
     box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
     border-radius: 10px;
     border: 1px solid rgba(0,0,0, 0.1);
}
.admin-home-history-container p, .admin-home-history-container h2
{
     margin: 5px;
}
.admin-home-history-container h2
{
     font-size: 1.2em;
}
.admin-home-history-container .admin-upcoming-schedule
{
     border-left: solid thick blue;
     margin-bottom: 20px;
}
.border-left-red
{
     border-left: solid thick #8C1616 !important;
}
.border-left-green
{
     border-left: solid thick green !important;
}
.admin-stat-container
{
     background: white;
     width: 150px;
     border-radius: 10px;
     height: 140px;
     text-align: center;
     box-shadow: 1px 1px 6px rgba(0,0,0, 0.2);
     border: 0.2px solid rgba(0,0,0, 0.1);
     padding: 10px 15px;
}
.admin-stat-container h2, .admin-stat-container p, .time-button-container
{
     margin-bottom: 20px;
}
.admin-stat-container h2, .admin-stat-container p, .time-button-container h4
{
     margin: 3px auto;
}
.admin-layout-dashboard-container .flex-start
{
     gap: 30px;
}
.admin-stat-icon
{
     height: 40px;
     width: 40px;
     margin: 0 auto;
}
.margin-top-20
{
     margin-top: 20px;
}
.margin-top-50
{
     margin-top: 50px;
}
.adminpage-section-beside-nav .flex-start-align-top
{
     display: flex;
     justify-content: start;
     gap: 130px;
     align-items: start;
}
.flex-between-align-top
{
     display: flex;
     justify-content: space-between;
     gap: 130px;
     align-items: start;
}
.flex-between-align-top h4
{
     margin: 10px 0;
}
.flex-between-align-top input, .bio-input
{
     margin-bottom: 30px;
     padding: 5px 10px;
     width: 250px;
     border: solid thin #2F49D1;
     background: hsl(230, 64%, 95%);
}
.bio-input
{
     height: 210px;
     width: 400px;
     display: block;
     line-height: 20px;
}
.flex-between-align-top input:focus, .bio-input:focus
{
     box-shadow: 2px 2px 20px rgba(0,0,0,0.2);
     outline: none;
}
.admin-home-history-container table
{
     width: 100%;
     text-align: left;
     border-collapse: collapse;
}
.admin-home-history-container table thead
{
     background: #F4F5F7;
     border-radius: 10px;
}
.admin-home-history-container table thead th
{
     padding: 10px;
     font-size: 0.9em;
     text-align: left;
     margin: 0;
}
.admin-home-history-container table tbody tr td
{
     padding: 15px 10px;
     box-sizing: 1px 1px 4px #F4F5F7;
     border-bottom: 2px solid  #F4F5F7;
}
.admin-layout-dashboard-container section.flex-start
{
     gap: 50px;
}
.admin-upcoming-schedule
{
     padding: 5px 10px;
}
.admin-layout-dashboard-container .grid-center
{
     display: grid;
     justify-content: center;
     gap: 2px;
}
.admin-scheduler-page
{
     min-height: 85vh;
}
.admin-scheduler-page table
{
     background: white;
     border-collapse: collapse;
     border-radius: 10px;
     box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
     border: 0.5px solid rgba(0,0,0, 0.1);
     width: 80%;
}
.admin-scheduler-page table thead
{
     background: #F4F5F7;
     text-align: left;
}
.admin-scheduler-page table thead th
{
     padding: 15px 10px;
}
.admin-scheduler-page table tbody tr
{
     border-bottom: solid 2px #F4F5F7;
     box-shadow: 1px 1px 3px #F4F5F7;
     padding: 0 10px;
}
.admin-scheduler-page table tbody .time-buttons
{
     padding: 15px;
     border: none;
     margin: 0 10px;
     box-shadow: 1px 1px 4px rgba(0,0,0,0.2);
}
.admin-scheduler-page .time-buttons
{
     padding: 10px 20px;
     border: solid thin lightgreen;
     margin: 10px 15px;
     box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
}
.admin-scheduler-page table tbody .save-button
{
     padding: 15px 25px;
     border: none;
     color: white;
     margin: 0 10px;
     background: green;
     box-shadow: 1px 1px 4px rgba(0,0,0,0.2);
}
.admin-scheduler-page table tbody .delete-button
{
     background: transparent !important;
     color: rgb(255, 70, 20);
     border: solid thin rgb(255, 70, 20) !important;
}
.admin-scheduler-page table tbody tr td
{
     padding: 10px 10px;
}
.profile-content-wrapper
{
     border-radius: 10px;
     background: white;
     box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
     border: 1px solid rgba(0,0,0, 0.1);
     padding: 15px 20px;
     width: 80%;
     box-sizing: border-box;
}
.admin-scheduler-page .admin-profile-hero
{
     width: 80%;
     height: 400px;
     background: white;
     overflow: hidden;
     position: relative;
     box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
     border: 1px solid rgba(0,0,0, 0.1);
     border-radius: 10px;
     margin: 20px 0 40px 0;
}
.admin-profile-hero-footer
{
     height: 50px;
     background: #F4F5F7;
     padding: 20px;
     width: 100%;
     position: absolute;
     bottom: 0;
}
.admin-profile-hero-image
{
     width: 100%;
     height: 150%;
}
.admin-profile-picture
{
     width: 120px;
     height: 120px;
     border-radius: 50%;
}
.admin-profile-footer-content
{
     margin-top: -80px;
     margin-left: 70px;
}
.admin-profile-quote
{
     margin-top: 20px;
     width: 150px;
     background: rgba(255, 255, 255, 0.7);
     padding: 5px 10px;
}
.admin-profile-summary
{
     line-height: 20px;
     width: 90%;
}
